import React from 'react';
import mapboxgl from 'mapbox-gl';
import scrollama from 'scrollama'
import { FaGuitar } from 'react-icons/fa'
import { TiLocation } from 'react-icons/ti'
import { BsPeopleFill } from 'react-icons/bs'
import { GiPianoKeys } from 'react-icons/gi'
import SEO from "../components/seo"
import { Link, graphql } from 'gatsby'
import '../styles/biggest-concerts.css'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import Img from "gatsby-image"
import onePercentClubSocial from '../images/biggest-concerts/biggest-concerts-social.jpg'
import ShareButtons from '../components/ShareButtons/ShareButtons';


const alignments = {
    'left': 'lefty',
    'center': 'centered',
    'right': 'righty'
}

const layerTypes = {
    'fill': ['fill-opacity'],
    'line': ['line-opacity'],
    'circle': ['circle-opacity', 'circle-stroke-opacity'],
    'symbol': ['icon-opacity', 'text-opacity'],
    'raster': ['raster-opacity'],
    'fill-extrusion': ['fill-extrusion-opacity']
}

class BiggestConcerts extends React.Component {

    constructor(props) {
        super(props);

        const config = props.data['allBiggestconcertsJson']['edges'][0]['node'];

        this.state = {
            currentChapter: config.chapters[0],
            config: config
        }

    }

    componentDidMount() {
        const setState = this.setState.bind(this);
        const mapStart = this.state.config.chapters[0].location;
        const config = this.state.config;

        mapboxgl.accessToken = 'pk.eyJ1IjoiamltaWNoYWlsaWRpcyIsImEiOiJjazBlNzZxeWUwZWlkM29yejJ5aWRnaXdhIn0.dklyDYSF16YU0FTyLgJydw';

        const map = new mapboxgl.Map({
            container: this.mapContainer,
            style: this.state.config.style,
            center: mapStart.center,
            zoom: mapStart.zoom,
            pitch: mapStart.pitch,
            bearing: mapStart.bearing
        });

        const marker = new mapboxgl.Marker();
        if (this.state.config.showMarkers) {
            marker.setLngLat(mapStart.center).addTo(map);
        }

        function getLayerPaintType(layer) {
            var layerType = map.getLayer(layer).type;
            return layerTypes[layerType];
        }


        function setLayerOpacity(layer) {
            var paintProps = getLayerPaintType(layer.layer);
            paintProps.forEach(function (prop) {
                map.setPaintProperty(layer.layer, prop, layer.opacity);
            });
        }

        // instantiate the scrollama
        const scroller = scrollama();

        map.on('load', function () {
            map.scrollZoom.disable();
            map.dragPan.disable();


            scroller.setup({
                step: '.step',
                offset: 0.9,
                progress: true
            }
            ).onStepEnter(response => {
                const chapter = config.chapters.find(chap => chap.id === response.element.id);

                trackCustomEvent({
                    category: 'BiggestConcerts',
                    action: 'EnteredStep',
                    label: chapter.id
                });

                setState({ currentChapter: chapter });

                map.flyTo(chapter.location);
                if (config.showMarkers) {
                    marker.setLngLat(chapter.location.center);
                }
                if (chapter.onChapterEnter?.length > 0) {
                    chapter.onChapterEnter.forEach(setLayerOpacity);
                }
            })
                .onStepExit(response => {
                    var chapter = config.chapters.find(chap => chap.id === response.element.id);
                    if (chapter.onChapterExit?.length > 0) {
                        chapter.onChapterExit.forEach(setLayerOpacity);
                    }
                });
        });

        window.addEventListener('resize', scroller.resize);
    }

    render() {
        const currentChapterID = this.state.currentChapter.id;
        const config = this.state.config;

        return (
            <div className="biggestConcerts">
                <SEO 
                title="The Biggest Concerts Ever | An Interactive Journey" 
                description="An interactive journey through the highest-attended concerts ever, their iconic venues, and their interesting backstories." 
                pathname='/biggest-concerts' 
                image={{ src: onePercentClubSocial, width: 1200, height: 600 }} />
                {config.title &&
                    <div id="header">
                        <div id="header-text">
                            {/* <img src={header} alt="biggest-concerts" /> */}
                            <h2><strong>{config.title}</strong></h2>
                            <h4>{config.subtitle}</h4>
                        by <Link to="/">Thousand Words</Link>
                        </div>

                    </div>
                }
                <div ref={el => this.mapContainer = el} id="biggest-concerts-map" className="mapContainer" />
                <div id="story">
                    <div id="features" className={alignments[config.alignment]}>
                        {
                            config.chapters.map(chapter =>
                                <Chapter key={chapter.id} {...chapter} currentChapterID={currentChapterID} />
                            )
                        }
                    </div>
                    <div id="footer" style={{ borderTop: '1px solid black' }}>
                        <ShareButtons url="https://www.thousandwords.blog/biggest-concerts"></ShareButtons>
                        <p>data from <a href="https://en.wikipedia.org/wiki/List_of_highest-attended_concerts" target="_blank" rel="noopener noreferrer">wikipedia</a></p>
                        <p style={{ width: '80vw', margin: 'auto' }}>sources: <a href="https://societyofrock.com/1985-the-beach-boys-jimmy-page-bring-the-fireworks-on-4th-of-july-with-explosive-surfin-usa-duet/" target="_blank" rel="noopener noreferrer">Beach Boys Philadelphia</a>, <a href="https://en.wikipedia.org/wiki/Paz_Sin_Fronteras#Paz_Sin_Fronteras_II" target="_blank" rel="noopener noreferrer">Paz Sin Fronteras</a>, <a href="https://en.wikipedia.org/wiki/Rendez-vous_Houston" target="_blank" rel="noopener noreferrer">Rendez-vous Houston</a>, <a href="https://ultimateclassicrock.com/rolling-stones-rio-2006/" target="_blank" rel="noopener noreferrer">Rolling Stones Rio</a>, <a href="https://en.wikipedia.org/wiki/Live_8" target="_blank" rel="noopener noreferrer">Live 8</a>, <a href="https://www.nytimes.com/1991/09/29/world/heavy-metal-groups-shake-moscow.html" target="_blank" rel="noopener noreferrer">Heavy Metal Groups Shake Moscow</a>, <a href="https://stmuhistorymedia.org/metal-in-soviet-russia-monsters-of-rock-1991/#marker-87737-4" target="_blank" rel="noopener noreferrer">Metal in Soviet Russia</a>, <a href="https://en.parisinfo.com/discovering-paris/major-events/bastille-day-in-paris" target="_blank" rel="noopener noreferrer">Bastille Day Celebrations</a>, <a href="https://en.wikipedia.org/wiki/Paris_La_D%C3%A9fense_%E2%80%93_Une_Ville_En_Concert" target="_blank" rel="noopener noreferrer">Paris La Défense – Une Ville En Concert</a>, <a href="https://en.wikipedia.org/wiki/Jorge_Ben" target="_blank" rel="noopener noreferrer">Jorge Ben Jor</a>, <a href="https://www.bbc.co.uk/sounds/play/p02fdmlq" target="_blank" rel="noopener noreferrer">The biggest rock concert ever</a>, <a href="https://jeanmicheljarre.com/live/oxygen-in-moscow" target="_blank" rel="noopener noreferrer">Oxygen in Moscow</a></p>
                        <p></p>
                        <p><u>Enjoyed the story? Explore our other interactive projects:</u> </p>
                        <p style={{ width: '90vw', margin: 'auto' }}><Link to="/world-map-of-greeks"><u>The World Map of Greeks</u> - Every city in the world is represented by its Greek citizen with the most views on wikipedia.</Link></p>
                        <p style={{ width: '90vw', margin: 'auto' }}><Link to="/one-percent-club"><u>Europe's 1% Club</u> - How much income makes you as rich as 1% of your country's population?</Link></p>
                        <p style={{ marginTop: '20px' }}>We post a new story every two weeks on <Link to="/" rel="noopener noreferrer">thousandwords.blog</Link></p>
                    </div>
                </div>
            </div>

        )

    }
}

function Chapter({ id, title, city, date, img, venue, event, attendance, currentChapterID, description }) {
    const classList = id === currentChapterID ? "step active" : "step";
    const isPrimaryChapter = !id.includes('secondary');

    if (isPrimaryChapter) {
        return (
            <div id={id} className={classList}>
                <div className='chapter'>
                    <div className='chapter-header'>
                        <h3 className="title">{title}</h3>
                        <div className='chapter-subtitle'>
                            <h5>{city}, {date}</h5>
                        </div>
                    </div>

                    <div className="chapter-content">
                        <div className='chapter-box'>
                            <p> {title.includes('Jean-Michel') ? <GiPianoKeys className="icon" /> : <FaGuitar className="icon" />}  <br /> {event}</p>
                        </div>

                        <div className='chapter-box'>
                            <p> <TiLocation className="icon" /> <br /> {venue}</p>
                        </div>

                        <div className='chapter-box attendance'>
                            <p><BsPeopleFill className="icon" /> <br /> {attendance}</p>
                        </div>
                    </div>

                </div>
            </div>
        )
    } else {
        return (
            <div id={id} className={classList}>
                <div className='chapter'>
                    <Img fluid={img.childImageSharp.fluid}></Img>
                    <p style={{ marginTop: '8px' }}>{description}</p>
                </div>
            </div>
        )
    }
}

export const query = graphql`
query BiggestConcerts {
    allBiggestconcertsJson {
      edges {
        node {
          id
          chapters {
            id
            attendance
            alignment
            city
            date
            description
            event
            location {
              bearing
              center
              pitch
              zoom
            }
            title
            venue
            img {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          accessToken
          alignment
          byline
          showMarkers
          style
          subtitle
          title
        }
      }
    }
  }
  
`



export default BiggestConcerts;
